import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import axios from "axios";
var swal = require("sweetalert2");

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import IdleVue from "idle-vue";

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,

  idleTime: 350000, // 3 seconds
  startAtIdle: false,
});

Vue.config.productionTip = false;

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
var VueCookies = require("vue-cookies");
Vue.component(swal);

Vue.use(VueCookies);

Vue.component("multiselect", Multiselect);

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,  
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          let objtoken;
          try {
            const rs = await refreshToken();

            objtoken = rs;
          } catch (error) {
            swal.fire({
              icon: "warning",
              text: "Session Timeout!! Please Login ",
            });

            router.push("/login");
          }

          window.localStorage.setItem("token", objtoken.data.token);
          this.$cookies.set("refreshToken", objtoken.data.refreshToken, "1h");
          //objtoken.data

          return instance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

function refreshToken() {
  return instance.post("/api/SaleMan/RefreshToken/" + getLocalRefreshToken());
}

function getLocalRefreshToken() {
  const refreshToken = this.$cookies.get("refreshToken");

  //alert(refreshToken)

  return refreshToken;
}

function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken;
}

Vue.prototype.$http = instance;

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  onIdle() {
    
    swal
      .fire({
        icon: "info",
        title: "Session หมดอายุ",
        confirmButtonText: "ตกลง",
      })
      .then((result) => {
        if (result) {
          window.localStorage.clear();
          router.replace("/login");
          this.$cookies.remove("authen");
        }
      });
  },

  logout() {
    this.$router.push("/login");
    window.localStorage.clear();
  },
  render: (h) => h(App),
}).$mount("#app");
