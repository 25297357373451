import Vue from "vue";
import Router from "vue-router";
var VueCookies = require("vue-cookies");
Vue.use(VueCookies);

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      redirect: "/login",
      component: () => import("@/layouts/full-layout/FullLayout"),

      children: [
        {
          name: "Starter",
          path: "starter",
          meta: {
            requiresAuth: true,
          },
          component: () => import("@/views/Starter"),
        },
        {
          name: "refuel",
          path: "refuel",
          meta: {
            requiresAuth: true,
          },
          component: () => import("@/views/Refuel"),
        },

        /*
        {
          name: "Alert",
          path: "/ui-elements/alert",
          component: () => import("@/views/ui-elements/Alert"),
        },
        {
          name: "Avatar",
          path: "/ui-elements/avatar",
          component: () => import("@/views/ui-elements/Avatar"),
        },
        {
          name: "Badge",
          path: "/ui-elements/badge",
          component: () => import("@/views/ui-elements/Badge"),
        },
        {
          name: "Breadcrumb",
          path: "/ui-elements/breadcrumb",
          component: () => import("@/views/ui-elements/Breadcrumb"),
        },
        {
          name: "Buttons",
          path: "/ui-elements/buttons",
          component: () => import("@/views/ui-elements/Buttons"),
        },
        {
          name: "Button Group",
          path: "/ui-elements/button-group",
          component: () => import("@/views/ui-elements/ButtonGroup"),
        },

        {
          name: "Cards",
          path: "/ui-elements/cards",
          component: () => import("@/views/ui-elements/Cards"),
        },

        {
          name: "Dropdown",
          path: "/ui-elements/dropdown",
          component: () => import("@/views/ui-elements/Dropdown"),
        },
        {
          name: "Popover",
          path: "/ui-elements/popover",
          component: () => import("@/views/ui-elements/Popover"),
        },

        {
          name: "Toasts",
          path: "/ui-elements/toasts",
          component: () => import("@/views/ui-elements/Toasts"),
        },
        {
          name: "Tooltip",
          path: "/ui-elements/tooltip",
          component: () => import("@/views/ui-elements/Tooltip"),
        },

        */
      ],
    },
    {
      name: "Logout",
      path: "/Logout",
      component: () => import("@/views/Logout"),
    },
    {
      name: "Login",
      path: "/Login",
      component: () => import("@/views/Login"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var checkAuth = window.$cookies.get("authen");

    console.log(checkAuth);

    if (checkAuth == "true") {
      next();
      return;
    } else {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    }
    //next("/login");
  } else {
    next();
  }
});

export default router;
